import {DataTable} from '@management-ui/core';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import SchoolForm from '../../forms/SchoolForm';
import AuditTableProgressBar from '../Audit/AuditTableProgressBar';

const Table = ({trust}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((school) => {
    let redirect;
    if (trust) {
      redirect = reverse(routes.trusts.school, {trustID: trust.id, id: school.id});
    } else {
      redirect = reverse(routes.schools.detail, {id: school.id});
    }
    history.push(redirect);
  }, [history, trust]);

  return (
    <DataTable
      title={trust ? '' : 'Schools'}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        ...(trust ? [] : [
          {title: 'Trust', field: 'trust', render: school => school.trust?.name ?? 'Independent'},
        ]),
        {
          title: 'Latest Audit',
          field: 'latest_audit',
          sorting: false,
          render: item => item.latest_audit ? (
            <AuditTableProgressBar
              title={`${item.latest_audit.title} (${moment(item.latest_audit.date).format('DD/MM/YYYY')})`}
              summary={item.latest_audit.progress}
            />
          ) : <i>No Audits Completed</i>
        }
      ], [trust])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.school.getTableSchools(query, trust ? {trust: trust.id} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => reject());
      }), [services, trust])}
      newForm={{
        title: 'Add New School',
        render: (props) => <SchoolForm {...(trust ? {trustID: trust.id} : {})} school={{}} {...props}/>,
        onSaved: goToDetail
      }}
      onRowClick={goToDetail}
    />
  );
};

export default Table;
