import * as qs from 'qs';
import {API} from './API';

export class TrustService {
  async getTableTrusts(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTrusts(query, page, length);
  }

  async getTrusts(query, page, length) {
    return (await API.getConnection())
      .get('trusts', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getTrust(id, archive = false) {
    return (await API.getConnection())
      .get(`trusts/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveTrust(data) {
    let method = 'post';
    let url = 'trusts';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteTrust(trust) {
    return (await API.getConnection())
      .delete(`trusts/${trust.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
