import {ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Button, Paper, styled} from '@mui/material';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {ServiceContext} from '../../../../components/Services';
import {usePermissions} from '../../../../hooks';
import AnswerForm from '../../forms/AnswerForm';
import AuditFileForm from '../../forms/AuditFileForm';
import Questions from './Questions';
import SectionProgressBar from './SectionProgressBar';

const NoQuestions = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const SectionQuestions = ({audit, section, onUpdated, onNavigate}) => {
  const services = useContext(ServiceContext);
  const [selected, setSelected] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const {userCan} = usePermissions();
  const {online} = useSelector(state => state['general']);

  useEffect(() => {
    const edit = ['Assigned', 'Requires Amends'].indexOf(audit.status) >= 0 && userCan(['manage_audits'], true, false);
    setCanEdit(edit || userCan(['moderate_audits'], true, false));
    setCanDelete(userCan(['moderate_audits'], true, false));
  }, [audit, userCan]);

  const handleDeleteSection = useCallback((confirmed) => {
    if (confirmed) {
      services.audit.deleteSection(audit, section).then((updated) => {
        onUpdated(updated);
        onNavigate();
      }).catch(() => null);
    }
    setShowDeleteSection(false);

  }, [services, audit, section, onUpdated, onNavigate]);

  const handleDeleteQuestion = useCallback((confirmed) => {
    if (confirmed) {
      services.audit.deleteQuestion(audit, section, selected).then((updated) => onUpdated(updated)).catch(() => null);
    }
    setShowDeleteQuestion(false);
  }, [services, audit, section, selected, onUpdated]);

  const handleDownload = useCallback((file) => {
    services.audit.downloadFile(audit, file)
      .then(() => null)
      .catch(() => null);
  }, [services, audit]);

  const handleDeleteFile = useCallback((confirmed) => {
    if (confirmed) {
      services.audit.deleteFile(audit, selectedFile).then((updated) => onUpdated(updated)).catch(() => null);
    }
    setShowDeleteFile(false);
  }, [services, audit, selectedFile, onUpdated]);

  return section.questions.length > 0 ? (
    <>
      <SectionProgressBar section={section}/>
      <Paper overflow="hidden">
        <Questions
          questions={section.questions.map(q => ({...q, sectionIndex: section.index}))}
          onEdit={canEdit ? (question) => setSelected(question) || setShowEdit(true) : null}
          onDelete={online && canDelete ? (question) => setSelected(question) || setShowDeleteQuestion(true) : null}
          onUpload={online && canEdit ? (question) => setSelected(question) || setShowUpload(true) : null}
          onDeleteFile={online && canEdit ? (file) => setSelectedFile(file) || setShowDeleteFile(true) : null}
          onDownload={online ? handleDownload : null}
        />
        <FormDialog
          title="Provide Assessment"
          open={showEdit}
          maxWidth="lg"
          onClose={() => setShowEdit(false)}
          closeOnEscape={false}
          buttons={{save: 'Save', cancel: null}}
          headerCloseTitle="Cancel"
          headerCloseMessage="Are you sure you want to cancel editing this assessment?"
          render={(props) => (
            <AnswerForm
              {...props}
              audit={audit}
              section={section}
              question={selected ?? {}}
              onSaved={(updated, closeDialog) => onUpdated(updated) || setShowEdit(!closeDialog)}
            />
          )}
        />
        <ConfirmationDialog
          title="Delete Question"
          message="Are you sure you want to delete this question? This action can not be reversed"
          open={showDeleteQuestion}
          onClose={handleDeleteQuestion}
        />
        <FormDialog
          title="Upload File"
          open={showUpload}
          maxWidth="md"
          onClose={() => setShowUpload(false)}
          closeOnEscape={false}
          render={(props) => (
            <AuditFileForm
              {...props}
              audit={audit}
              section={section}
              question={selected ?? {}}
              file={{}}
              onSaved={(updated) => onUpdated(updated) || setShowUpload(false)}
            />
          )}
        />
        <ConfirmationDialog
          title="Delete File"
          message="Are you sure you want to delete this file?"
          open={showDeleteFile}
          onClose={handleDeleteFile}
        />
      </Paper>
    </>
  ) : canDelete ? (
    <NoQuestions>
      <p>This section now contains no questions, would you like to delete it?</p>
      <Button variant="contained" onClick={() => setShowDeleteSection(true)}>Yes</Button>
      <ConfirmationDialog
        title="Delete Section"
        message="Are you sure you want to delete this section? This action can not be reversed"
        open={showDeleteSection}
        onClose={handleDeleteSection}
      />
    </NoQuestions>
  ) : null;
};

export default SectionQuestions;
