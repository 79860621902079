import * as qs from 'qs';
import {API} from './API';

export class QuestionService {

  async getQuestions(query = {}) {
    return (await API.getConnection())
      .get('questions', {
        params: query,
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(API.handleError);
  }

  async getQuestion(id) {
    return (await API.getConnection())
      .get(`questions/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveQuestion(data) {
    let method = 'post';
    let url = 'questions';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteQuestion(question) {
    return (await API.getConnection())
      .delete(`questions/${question.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reorderQuestions(questions) {
    return (await API.getConnection())
      .put('questions', {questions})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
