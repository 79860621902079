import {DateField, name, Panes, RelationAutocomplete, Select, TextField} from '@management-ui/core';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {ServiceContext} from '../../../../components/Services';

const Fields = (
  {
    audit = {},
    includeAssignment = true,
    includeUser = false,
    prefix = '',
    multiplePanes = true,
    children
  }
) => {
  const services = useContext(ServiceContext);
  const [types, setTypes] = useState([]);
  const optionsLoaded = useRef(false);
  const [users, setUsers] = useState([]);
  const {online} = useSelector(state => state['general']);

  const formatSchool = useCallback((school) => `${school.name} (${school.trust ? school.trust.name : 'No Trust'})`, []);

  useEffect(() => {
    if (online && !optionsLoaded.current) {
      optionsLoaded.current = true;
      services.auditType.type.getTypes({}, 1, 500).then((retrievedTypes) => {
        services.user.getUsers({filter: {auditors: '1'}}, 1, 500).then((retrievedUsers) => {
          setTypes(retrievedTypes.data.map(({id, title}) => ({title, value: id})));
          setUsers(retrievedUsers.data.map((user) => ({title: name(user), value: user.id})));
        }).catch(() => null);
      }).catch(() => null);
    }
  }, [services, online]);

  const handleReset = useCallback((subject) => {
    if (subject.school) {
      subject.school_id = `${subject.school.id}`;
      subject.school_name = formatSchool(subject.school);
    }
    if (subject.user) {
      subject.user_id = `${subject.user.id}`;
    }
    return subject;
  }, [formatSchool]);

  return (
    <Panes
      entity={audit}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        ...(includeAssignment ? [
          {
            title: 'What & Where',
            fields: [
              audit?.id ? (
                <TextField
                  name="title"
                  prefix={prefix}
                  label="Category"
                  fieldProps={{disabled: true}}
                />
              ) : (
                <Select
                  name="type"
                  prefix={prefix}
                  label="Category"
                  options={types}
                  rules={{required: 'Please select an audit category'}}
                />
              ),
              <RelationAutocomplete
                valueName="school_id"
                titleName="school_name"
                prefix={prefix}
                label="School"
                rules={{required: 'Please select a school'}}
                loadOptions={query => (
                  services.school.getSchools({filter: {search: query}}, 1, 50)
                    .then(response => response.data.map(school => ({title: formatSchool(school), value: school.id})))
                    .catch(() => [])
                )}
              />,
            ]
          }, {
            title: 'When & Who',
            fields: [
              <DateField
                name="date"
                prefix={prefix}
                label="Audit Date"
              />,
              <Select
                name="user_id"
                prefix={prefix}
                label="Auditor"
                options={users}
                rules={{required: 'Please select an auditor'}}
              />
            ]
          }] : []),
        ...(includeUser ? [{
          title: 'User Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Person Completing the Audit"
            />,
            <TextField
              name="job_title"
              prefix={prefix}
              label="Job Title"
            />,
            <TextField
              name="head_teacher"
              prefix={prefix}
              label="Head Teacher"
            />,
            <TextField
              name="chair_of_governors"
              prefix={prefix}
              label="Chair of Governors"
            />,
            <TextField
              name="safeguarding_governor"
              prefix={prefix}
              label="Safeguarding Governor"
            />,
            <DateField
              name="signed_off"
              prefix={prefix}
              label="Date of Formal Sign-off"
            />
          ]
        }] : [])
      ]}
      title={multiplePanes ? null : 'Audit Details'}
    >{children}</Panes>
  );
};

export default Fields;
