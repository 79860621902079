import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const EditFields = ({question = {}, header = false, prefix = ''}) => {
  return (
    <Panes
      entity={question}
      prefix={prefix}
      panes={!header ? [
        {
          title: 'Question Body',
          fields: [
            <TextField
              name="body"
              prefix={prefix}
              label="Body"
              rules={{required: 'Please enter the body of this question'}}
              fieldProps={{multiline: true, rows: 6}}
            />,
            <TextField
              name="link"
              prefix={prefix}
              label="Link"
            />,
          ]
        }, {
          title: 'Criteria',
          fields: [
            <TextField
              name="not_met"
              prefix={prefix}
              label="Not Met"
              fieldProps={{multiline: true, rows: 3}}
            />,
            <TextField
              name="partly_met"
              prefix={prefix}
              label="Partly Met"
              fieldProps={{multiline: true, rows: 3}}
            />,
            <TextField
              name="fully_met"
              prefix={prefix}
              label="Fully Met"
              fieldProps={{multiline: true, rows: 3}}
            />,
          ]
        },
      ] : [
        {
          title: 'Header',
          fields: [
            <TextField
              name="body"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title for the header'}}
            />,
          ]
        }
      ]}
      title={''}/>
  );
};

export default EditFields;
