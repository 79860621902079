import {ConfirmationDialog, dateTime, DetailColumns, DetailPane, FormDialog} from '@management-ui/core';
import {Delete, Edit, Upload} from '@mui/icons-material';
import {Alert, Box, Paper, Snackbar} from '@mui/material';
import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import {truncate} from '../../../../formatters';
import routes from '../../../../routes';
import AuditTypeForm from '../../forms/AuditTypeForm';
import Sections from './Sections';

const TypeDetail = ({type, loading, onLoading, openDialogs, toggleDialog, onSaved}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('deleteType', false);
    if (confirmed) {
      onLoading(true);
      services.auditType.type.deleteType(type).then(() => {
        onLoading(false);
        history.push(routes.auditTypes.index);
      }).catch(() => onLoading(false));
    }
  }, [services, type, onLoading, toggleDialog, history]);

  const handleUpdate = useCallback((confirmed) => {
    toggleDialog('updateAudits', false);
    if (confirmed) {
      onLoading(true);
      services.auditType.type.updateAudits(type).then(() => {
        onLoading(false);
        toggleDialog('auditsUpdated', true);
      }).catch(() => onLoading(false));
    }
  }, [services, type, onLoading, toggleDialog]);

  return (
    <>
      <DetailColumns columns={[

        <DetailPane
          title="Category Details"
          actions={[
            {title: 'Edit Category', icon: <Edit/>, onClick: () => toggleDialog('editType', true)},
            {title: 'Update Audits', icon: <Upload/>, onClick: () => toggleDialog('updateAudits', true)},
            {title: 'Delete Category', icon: <Delete/>, onClick: () => toggleDialog('deleteType', true)},
          ]}
          details={[
            {title: 'Title', value: type.title},
            {title: 'Introduction', value: type.introduction ? truncate(type.introduction) : '-'}
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Edit Category"
                open={openDialogs['editType'] ?? false}
                onClose={() => toggleDialog('editType', false)}
                render={(props) => (
                  <AuditTypeForm
                    {...props}
                    type={type}
                    onSaved={onSaved}/>
                )}
              />
            ),
          ]}
        />,

        <DetailPane
          title="Admin Details"
          details={[
            {title: 'Created', value: dateTime(type.created_at)},
            {title: 'Last Updated', value: dateTime(type.updated_at)}
          ]}
        />,
      ]}/>

      <Paper marginTop={2} width="100%" component={Box}>
        <Sections type={type} loading={loading} onUpdated={onSaved}/>
      </Paper>

      <ConfirmationDialog
        title="Delete Category"
        message="Are you sure you want to delete this audit type?"
        open={openDialogs['deleteType']}
        onClose={handleDelete}
      />

      <ConfirmationDialog
        title="Update Audits"
        message={`Are you sure you want to rollout all changes made to this category to any unfinished '${type.title}' audits?`}
        open={openDialogs['updateAudits']}
        onClose={handleUpdate}
      />

      <Snackbar
        open={openDialogs['auditsUpdated']}
        autoHideDuration={4000}
        onClose={() => toggleDialog('auditsUpdated', false)}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <Alert onClose={() => toggleDialog('auditsUpdated', false)} severity="success">Unfinished '{type.title}' Audits Updated</Alert>
      </Snackbar>
    </>
  );
};

export default TypeDetail;
