import {Breadcrumbs, DataTable} from '@management-ui/core';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../components/Services';
import routes from '../../../routes';
import AuditTypeForm from '../forms/AuditTypeForm';

const AuditTypes = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((type) => {
    history.push(reverse(routes.auditTypes.detail, {id: type.id}));
  }, [history]);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Categories'}]}/>
      <Paper>
        <DataTable
          title="Categories"
          columns={useMemo(() => [
            {title: 'Title', field: 'title'}
          ], [])}
          loadData={query => new Promise((resolve, reject) => {
            services.auditType.type.getTableTypes(query)
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch(() => {
              reject();
            });
          })}
          newForm={{
            title: 'Add New Category',
            render: (props) => <AuditTypeForm type={{}} {...props}/>,
            onSaved: goToDetail
          }}
          onRowClick={goToDetail}
        />
      </Paper>
    </div>
  );
};

export default AuditTypes;
