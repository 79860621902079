import {DataTable, date, name} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import AuditStatus from '../../../../components/AuditStatus';
import {ServiceContext} from '../../../../components/Services';
import {usePermissions} from '../../../../hooks';
import routes from '../../../../routes';
import AuditForm from '../../forms/AuditForm';
import AuditTableProgressBar from '../Audit/AuditTableProgressBar';
import Filter from './Filter';

const Table = ({title = 'Audits', school, user}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const {userCan} = usePermissions();
  const [status, setStatus] = useState('ongoing');
  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const {online} = useSelector(state => state['general']);

  const goToDetail = useCallback((audit) => {
    history.push(reverse(routes.audits.detail, {id: audit.id}));
  }, [history]);

  const handleStatus = useCallback((changed) => {
    setStatus(changed);
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, []);

  return (
    <DataTable
      ref={tableRef}
      title={title}
      options={{
        search: online
      }}
      canRefresh={online}
      tableProps={{
        components: {
          Toolbar: (props) => (
            <>
              <MTableToolbar {...props}/>
              {online ? <Filter status={status} onChange={handleStatus}/> : null}
            </>
          )
        }
      }}
      columns={useMemo(() => [
        {title: 'Category', field: 'title'},
        ...(school ? [] : [{title: 'School', field: 'school', render: item => item.school.name}]),
        ...(user ? [] : [{title: 'Auditor', field: 'user', render: item => name(item.user)}]),
        {title: 'Date', field: 'date', render: item => item.date ? date(item.date) : '-'},
        {title: 'Status', field: 'status', sorting: false, render: item => <AuditStatus status={item.status}/>},
        {
          title: 'Progress',
          field: 'progress',
          sorting: false,
          render: item => <AuditTableProgressBar summary={item.progress}/>
        }
      ], [school, user])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        const params = {status};
        if (school) {
          params.school = school.id;
        }
        if (user) {
          params.user = user.id;
        }
        services.audit.getTableAudits(query, params)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => reject());
      }), [services, school, user, status])}
      newForm={useMemo(() => online ? userCan(['assign_audits'], {
        title: 'Start New Audit',
        render: (props) => (
          <AuditForm audit={{...(school ? {school} : {}), ...(user ? {user} : {})}} includeUser={true} {...props}/>
        ),
        onSaved: goToDetail
      }, null) : null, [school, user, goToDetail, userCan, online])}
      onRowClick={goToDetail}
    />
  );
};

export default Table;
