import {useDialogs, useErrors} from '@management-ui/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import SectionDetail from './SectionDetail';
import TypeDetail from './TypeDetail';
import Wrapper from './Wrapper';

const AuditType = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({type: 'Sorry the type could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs([
    'editType', 'updateAudits', 'auditsUpdated', 'deleteType', 'editSection', 'deleteSection'
  ]);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [section, setSection] = useState(null);

  const loadAudit = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.auditType.type.getType(id).then(retrieved => {
        setType(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('type', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadAudit(match.params?.id);
    }
  }, [history, match, type, loadAudit]);

  useEffect(() => {
    if (type) {
      if (match.params?.sectionID) {
        const found = type.sections.find(s => `${s.id}` === match.params.sectionID);
        if (found) {
          setSection(found);
        } else {
          history.push(reverse(routes.auditTypes.detail, {id: type.id}));
        }
      } else {
        setSection(null);
      }
    }
  }, [history, match, type]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (type) {
      header = type.title;
      if (section) {
        trail.push({title: header, link: reverse(routes.auditTypes.detail, {id: type.id})});
        trail.push({title: 'Sections', link: ''});
        header = `${header} / ${section.title}`;
        trail.push({title: section.title, link: ''});
      } else {
        trail.push({title: header, link: ''});
      }
    }
    setTitle(header);
    setCrumbs(trail);
  }, [type, section]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('editType', false);
    toggleDialog('editSection', false);
    if (updated) {
      setType(updated);
    } else if (type?.id) {
      loadAudit(type.id);
    }
  }, [toggleDialog, setType, loadAudit, type]);

  return (
    <Wrapper title={title} loading={loading} crumbs={crumbs} errors={errors}>
      {type?.id ? (
        section ? (
          <SectionDetail
            type={type}
            section={section}
            openDialogs={openDialogs}
            toggleDialog={toggleDialog}
            loading={loading}
            onLoading={setLoading}
            onSaved={handleSaved}
            onTypeUpdated={setType}
          />
        ) : (
          <TypeDetail
            type={type}
            loading={loading}
            onLoading={setLoading}
            openDialogs={openDialogs}
            toggleDialog={toggleDialog}
            onSaved={handleSaved}
          />
        )
      ) : null}
    </Wrapper>
  );
};

export default AuditType;
