import {Authenticated} from '@management-ui/core';
import {
  CorporateFare,
  Dashboard as DashboardIcon,
  FactCheck,
  FormatListNumbered,
  Language,
  Quiz,
  School as SchoolIcon,
  Security,
  Storage
} from '@mui/icons-material';
import {Box} from '@mui/material';
import * as React from 'react';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import logo from '../../assets/safeguarding-support.png';
import {usePermissions} from '../../hooks';
import routes from '../../routes';
import {logout} from '../../store/actions/auth';
import {setOnline} from '../../store/actions/general';
import OnlineStatus from './OnlineStatus';
import Audit from './screens/Audit';
import Audits from './screens/Audits';
import AuditType from './screens/AuditType';
import AuditTypes from './screens/AuditTypes';
import Dashboard from './screens/Dashboard';
import Management from './screens/Management';
import Questions from './screens/Questions';
import School from './screens/School';
import Schools from './screens/Schools';
import Trust from './screens/Trust';
import Trusts from './screens/Trusts';
import User from './screens/User';
import Users from './screens/Users';

const Admin = (props) => {
  const {user, userCan} = usePermissions();
  const dispatch = useDispatch();
  const {online} = useSelector(state => state['general']);

  const userCanOrRedirect = useCallback((permission, can = null) => {
    const hasPermission = userCan(permission, can);
    return hasPermission ?? <Redirect to={`${routes.dashboard}`}/>;
  }, [userCan]);

  return (
    <Authenticated
      title="Safeguarding Support"
      logo={(
        <Box marginY={1} display="flex" alignItems="center" justifyContent="center">
          <img
            src={logo}
            alt="Safeguarding Support"
            style={{width: '80%'}}/>
        </Box>
      )}
      userName={user ? user.first_name : ''}
      nav={[
        {title: 'Dashboard', route: routes.dashboard, icon: <DashboardIcon/>},
        ...userCan(
          ['manage_audits'], [
            {
              title: 'Audits',
              route: routes.audits.index,
              icon: <FactCheck/>
            }
          ], []),
        ...userCan(
          ['manage_trusts'], [
            {
              title: 'Trusts',
              route: routes.trusts.index,
              icon: <CorporateFare/>,
              disabled: !online
            },
            {
              title: 'Schools',
              route: routes.schools.index,
              icon: <SchoolIcon/>,
              disabled: !online
            }
          ], []),
        ...userCan(
          ['manage_audit_types'], [
            {
              title: 'Questions',
              route: routes.questions.index,
              icon: <Quiz/>,
              disabled: !online
            }, {
              title: 'Categories',
              route: routes.auditTypes.index,
              icon: <FormatListNumbered/>,
              disabled: !online
            }
          ], []),
        ...userCan(['manage_users'], [{
          title: 'Users',
          route: routes.users.index,
          icon: <Security/>,
          disabled: !online
        }], []),
        ...userCan(
          ['change_settings'],
          [{
            title: 'Management',
            route: routes.management,
            icon: <Storage/>,
            disabled: !online
          }],
          []
        ),
        ...(process.env.NODE_ENV === 'development' ? [{
          title: online ? 'Go Offline' : 'Go Online',
          onClick: () => dispatch(setOnline(!online)),
          icon: <Language/>
        }] : [])
      ]}
      sidebarContent={<OnlineStatus/>}
      onLogout={() => dispatch(logout())}
    >
      <Switch>

        <Route
          exact
          path={routes.dashboard}
          render={routeProps => <Dashboard {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.audits.index}
          render={routeProps => userCanOrRedirect(['manage_audits'], <Audits {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.audits.detail}
          render={routeProps => userCanOrRedirect(['manage_audits'], <Audit {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.audits.tab}
          render={routeProps => userCanOrRedirect(['manage_audits'], <Audit {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.audits.section}
          render={routeProps => userCanOrRedirect(['manage_audits'], <Audit {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.questions.index}
          render={routeProps => userCanOrRedirect(['manage_audit_types'], <Questions {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.trusts.index}
          render={routeProps => userCanOrRedirect(['manage_trusts'], <Trusts {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.trusts.detail}
          render={routeProps => userCanOrRedirect(['manage_trusts'], <Trust {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.trusts.school}
          render={routeProps => userCanOrRedirect(['manage_trusts'], <School {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.schools.index}
          render={routeProps => userCanOrRedirect(['manage_trusts'], <Schools {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.schools.detail}
          render={routeProps => userCanOrRedirect(['manage_trusts'], <School {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.auditTypes.index}
          render={routeProps => userCanOrRedirect(['manage_audit_types'], <AuditTypes {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.auditTypes.detail}
          render={routeProps => userCanOrRedirect(['manage_audit_types'], <AuditType {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.auditTypes.section}
          render={routeProps => userCanOrRedirect(['manage_audit_types'], <AuditType {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.users.index}
          render={routeProps => userCanOrRedirect(['manage_users'], <Users {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.users.detail}
          render={routeProps => userCanOrRedirect(['manage_users'], <User {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.management}
          render={routeProps => userCanOrRedirect(['change_settings'], <Management {...props} {...routeProps} />)}/>

      </Switch>
    </Authenticated>
  );
};

export default Admin;
