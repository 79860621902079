import {dateTime, DetailColumns, DetailPane, FormDialog, useDialogs, useErrors} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import FileUploader from '../../../../components/FileUploader';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import SchoolForm from '../../forms/SchoolForm';
import Table from '../Audits/Table';
import Wrapper from './Wrapper';

const School = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({school: 'Sorry the school could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState(null);
  const [logo, setLogo] = useState(null);

  const loadSchool = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.school.getSchool(id).then(retrieved => {
        setSchool(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('school', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadSchool(match.params?.id);
    }
  }, [history, match, school, loadSchool]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [];
    if (school) {
      header = school.name;
      if (school.trust) {
        trail.push({title: 'Trusts', link: routes.trusts.index});
        const trustLink = reverse(routes.trusts.detail, {id: school.trust.id});
        trail.push({title: school.trust.name, link: trustLink});
        trail.push({title: 'Schools', link: trustLink});
      } else {
        trail.push({title: 'Schools', link: routes.schools.index});
      }
      trail.push({title: header, link: ''});
      if (school.logo) {
        services.school.logo(school)
          .then(image => setLogo(image))
          .catch(() => null);
      }
    }
    setTitle(header);
    setCrumbs(trail);
  }, [services, school]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setSchool(updated);
    } else if (school?.id) {
      loadSchool(school.id);
    }
  }, [toggleDialog, setSchool, loadSchool, school]);

  const handleUpload = useCallback((image) => (
    services.school.uploadLogo(school, image)
  ), [services, school]);

  const handleUploaded = useCallback((image) => {
    setLogo(image);
  }, []);

  return (
    <Wrapper title={title} module={school} loading={loading} crumbs={crumbs} errors={errors}>
      {school?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="School Details"
              actions={[
                {title: 'Edit School', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
              ]}
              details={[
                {title: 'Name', value: school.name},
                {
                  title: 'Trust',
                  value: school.trust?.name ?? 'Independent',
                  route: school.trust ? reverse(routes.trusts.detail, {id: school.trust.id}) : null
                },
                {title: 'Type', value: school.type?.name ?? '-'},
                {title: 'Ofsted Rating', value: school.rating?.name ?? '-'},
                {title: 'View on Arlo', value: 'Click here', link: school.arlo.link}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit School"
                    open={openDialogs['edit'] ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <SchoolForm
                        {...props}
                        school={school}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
              ]}
            />,

            <DetailPane
              title="Logo"
              collapseChildrenHeight={false}
            >
              <Box paddingX={2}>
                <FileUploader
                  title=""
                  image={logo}
                  prompt="Drop an image here or click to select one from your computer"
                  onUpload={handleUpload}
                  onUploaded={handleUploaded}
                />
              </Box>
            </DetailPane>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(school.created_at)},
                {title: 'Last Updated', value: dateTime(school.updated_at)}
              ]}
            />,
          ]}/>

          <Paper component={Box} marginTop={2} width="100%">
            <Table school={school}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
};

export default School;
