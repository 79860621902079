import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import {CopyAll} from '@mui/icons-material';
import {IconButton, InputAdornment, Link, styled, Tooltip} from '@mui/material';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {ServiceContext} from '../../../../components/Services';
import Text from '../../../../components/Text';
import RatingField from '../RatingField';

const Question = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)}`,
  width: '100%',

  '& > p': {
    fontSize: '1.2em',
    margin: `0 0 ${theme.spacing(1)}`,
    padding: 0,
  },
}));

const IMPACTS = ['Best Practice', 'Compliance'].map(i => ({title: i, value: i}));
const TIMESCALES = ['Immediately', 'ASAP', 'This Term', 'This Academic Year', 'Other'].map(i => ({title: i, value: i}));

const Fields = ({audit, question = {}, onLoading, onSaved, prefix = '', multiplePanes = true, children}) => {
  const services = useContext(ServiceContext);
  const {online} = useSelector(state => state['general']);
  const {watch, setValue} = useFormContext();
  const actionName = useMemo(() => `${prefixWithSeparator(prefix)}action`, [prefix]);
  // noinspection JSCheckFunctionSignatures
  const rating = watch(`${prefixWithSeparator(prefix)}rating`, null);
  // noinspection JSCheckFunctionSignatures
  const lead = watch(`${prefixWithSeparator(prefix)}lead`, null);
  // noinspection JSCheckFunctionSignatures
  const action = watch(actionName, null);

  useEffect(() => {
    if (rating === '3' && !action) {
      setValue(actionName, 'None');
    }
  }, [rating, action, actionName, setValue]);

  const handleLead = useCallback(async () => {
    if (lead) {
      onLoading(true);
      let updatedAudit = audit;
      for (let auditSection of audit.sections) {
        for (let auditQuestion of auditSection.questions.filter(q => q.id !== question.id)) {
          if (auditQuestion.lead !== lead) {
            auditQuestion.lead = lead;
            if (!online) {
              const saved = await services.audit.saveQuestion(audit, auditSection, {
                id: auditQuestion.id,
                lead
              }).catch(() => null);
              if (saved) {
                updatedAudit = saved;
              } else {
                onLoading(false);
                return;
              }
            }
          }
        }
      }
      if (online) {
        await services.audit.updateLead(audit, lead).catch(() => null);
      }
      onSaved(updatedAudit);
      onLoading(false);
    }
  }, [services, online, audit, question, lead, onLoading, onSaved]);

  return (
    <Panes
      entity={question}
      prefix={prefix}
      panes={[
        {
          title: 'Assessment',
          fields: [
            <Question>
              <p><Text text={question.body}/></p>
              {question.link ? <Link href={question.link} target="_blank">Find out More</Link> : null}
            </Question>,
            <RatingField
              prefix={prefix}
              question={question}
            />,
          ]
        }, {
          title: 'Support & Actions',
          fields: [
            <TextField
              name="evidence"
              prefix={prefix}
              label="Evidence"
              fieldProps={{multiline: true, rows: 8}}
            />,
            <TextField
              name="lead"
              prefix={prefix}
              label="Lead Person"
              fieldProps={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy to all questions">
                        <span>
                          <IconButton disabled={!lead} onClick={handleLead}>
                            <CopyAll/>
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  )
                }
              }}
            />,
            <TextField
              name="action"
              prefix={prefix}
              label="Action"
              fieldProps={{multiline: true, rows: 3}}
            />,
            <Select
              name="impact"
              prefix={prefix}
              label="Impact"
              options={IMPACTS}
            />,
            <Select
              name="timescale"
              prefix={prefix}
              label="Timescale"
              options={TIMESCALES}
            />,
            <TextField
              name="progress"
              prefix={prefix}
              label="Progress"
              fieldProps={{multiline: true, rows: 3}}
            />
          ]
        },
      ]}
      title={multiplePanes ? null : 'Question Details'}
    >{children}</Panes>
  );
}

export default Fields;
