import * as qs from 'qs';
import {API} from './API';

export class SchoolService {

  async getTableSchools(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getSchools(query, page, length);
  }

  async getSchools(query, page, length) {
    return (await API.getConnection())
      .get('schools', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getSchool(id, archive = false) {
    return (await API.getConnection())
      .get(`schools/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveSchool(data) {
    let method = 'post';
    let url = 'schools';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async logo(school) {
    return (await API.getConnection())
      .get(`schools/${school.id}/logo`, {responseType: 'arraybuffer'})
      .then(response => `data:${response.headers['content-type']};base64,${Buffer.from(response.data, 'binary').toString('base64')}`)
      .catch(error => API.handleError(error));
  }

  async uploadLogo(school, file) {
    const body = new FormData();
    body.append('logo', file);
    return (await API.getConnection())
      .post(`schools/${school.id}/upload`, body, {responseType: 'arraybuffer'})
      .then(response => `data:${response.headers['content-type']};base64,${Buffer.from(response.data, 'binary').toString('base64')}`)
      .catch(error => API.handleError(error));
  }

  async deleteSchool(school) {
    return (await API.getConnection())
      .delete(`schools/${school.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
