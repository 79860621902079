import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import EditFields from './EditFields';
import LinkFields from './LinkFields';

const QuestionForm = forwardRef((
  {question, link = false, header = false, searchFilters = {}, onSave = null, onSaved},
  ref
) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const handleSave = useCallback(({id, body, link, not_met, partly_met, fully_met, question_id}) => {
    const data = {id, body, link, not_met, partly_met, fully_met, question_id};
    if (header) {
      data.is_header = true;
    }
    return onSave ? onSave(data) : services.question.saveQuestion(data);
  }, [services, onSave, header])

  return (
    <BaseForm
      ref={formRef}
      entity={question}
      type="question"
      fieldsComponent={(props) => link ? <LinkFields searchFilters={searchFilters} {...props}/> :
        <EditFields header={header} {...props}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default QuestionForm;
