import {ConfirmationDialog, ReorderingPanel} from '@management-ui/core';
import {Delete, Edit} from '@mui/icons-material';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ManageQuestion from './ManageQuestion';

const Questions = ({type, section, onUpdated}) => {
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDetach, setShowDetach] = useState(false);
  const [selected, setSelected] = useState({});

  const formatQuestions = useCallback((list) => (
    list.map((question) => ({
      entity: question,
      attributes: [`${section.index}.${question.index} - ${question.body}${question.link ? ` (${question.link})` : ''}`]
    }))
  ), [section]);

  useEffect(() => {
    if (section) {
      setQuestions(formatQuestions(section.questions));
    }
  }, [section, formatQuestions]);

  const handleSaved = useCallback((updated) => {
    setShowEdit(false);
    onUpdated(updated);
  }, [onUpdated]);

  const handleDetach = useCallback((confirmed) => {
    setShowDetach(false);
    if (confirmed) {
      setLoading(true);
      services.auditType.question.detachQuestion(type, section, selected).then(updated => {
        setLoading(false);
        onUpdated(updated);
      }).catch(() => setLoading(false));
    }
  }, [services, type, section, selected, onUpdated]);

  const handleReorder = useCallback((reordered) => {
    setQuestions(formatQuestions(reordered));
    return services.auditType.question.reorderQuestions(type, section, reordered.map((question, index) => ({
      id: question.id,
      index: index + 1
    })));
  }, [services, type, section, formatQuestions]);

  return (
    <>
      <ReorderingPanel
        title="Questions"
        items={questions}
        loading={loading}
        onLoading={setLoading}
        controls={[
          {
            title: 'Edit Question',
            icon: <Edit/>,
            onClick: (question) => setSelected(question) || setShowEdit(true)
          },
          {
            title: 'Detach Question',
            icon: <Delete/>,
            onClick: (question) => setSelected(question) || setShowDetach(true)
          }
        ]}
        onNew={() => setSelected(null) || setShowEdit(true)}
        onReorder={handleReorder}
        onReordered={onUpdated}
      />
      <ManageQuestion
        type={type}
        section={section}
        open={showEdit}
        maxWidth="md"
        question={selected}
        onClose={() => setShowEdit(false)}
        onSaved={handleSaved}
      />
      <ConfirmationDialog
        title="Detach Question"
        message="Are you sure you want to detach this question?"
        open={showDetach}
        onClose={handleDetach}
      />
    </>
  );
}

export default Questions;
